// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-blogs-college-bootcamp-or-self-taught-mdx": () => import("./../../../src/pages/blogs/college-bootcamp-or-self-taught.mdx" /* webpackChunkName: "component---src-pages-blogs-college-bootcamp-or-self-taught-mdx" */),
  "component---src-pages-blogs-how-long-to-become-a-web-developer-mdx": () => import("./../../../src/pages/blogs/how-long-to-become-a-web-developer.mdx" /* webpackChunkName: "component---src-pages-blogs-how-long-to-become-a-web-developer-mdx" */),
  "component---src-pages-blogs-how-to-make-100-000-a-year-freelancing-mdx": () => import("./../../../src/pages/blogs/how-to-make-100-000-a-year-freelancing.mdx" /* webpackChunkName: "component---src-pages-blogs-how-to-make-100-000-a-year-freelancing-mdx" */),
  "component---src-pages-blogs-top-3-programming-languages-for-beginners-mdx": () => import("./../../../src/pages/blogs/top-3-programming-languages-for-beginners.mdx" /* webpackChunkName: "component---src-pages-blogs-top-3-programming-languages-for-beginners-mdx" */),
  "component---src-pages-courses-jsx": () => import("./../../../src/pages/courses.jsx" /* webpackChunkName: "component---src-pages-courses-jsx" */),
  "component---src-pages-hire-jsx": () => import("./../../../src/pages/hire.jsx" /* webpackChunkName: "component---src-pages-hire-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */)
}

